import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {
  loadingDialogStatus: boolean = false;
  infoToastStatus: boolean = false;
  infoToastMessage: string = "";

  constructor() { }

  async showLoadingToast() {
    this.loadingDialogStatus = true;
  }

  hideLoadingToast() {
    this.loadingDialogStatus = false
  }

  async showInfoToast(message: string) {
    this.infoToastStatus = true;
    this.infoToastMessage = message
    await this.delayedFunction(3 * 1000);
    this.hideInfoToast()
  }

  hideInfoToast() {
    this.infoToastStatus = false
  }

  delayedFunction(delay: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  }
}
